<template>
    <div class="register-address">
        <ValidationObserver>
            <ul class="radio-group">
                <li class="radio-group__item">
                    <input :id="name + '-1'" v-model="address.type" type="radio" :name="name" value="company">
                    <label :for="name + '-1'">{{ $t('address.company') }}</label>
                </li>
                <li class="radio-group__item">
                    <input :id="name + '-2'" v-model="address.type" type="radio" :name="name" value="male">
                    <label :for="name + '-2'">{{ $t('address.male') }}</label>
                </li>
                <li class="radio-group__item">
                    <input :id="name + '-3'" v-model="address.type" type="radio" :name="name" value="female">
                    <label :for="name + '-3'">{{ $t('address.female') }}</label>
                </li>
            </ul>

            <div v-if="address.type === 'company'">
                <ValidationProvider v-slot="v" rules="required" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.name">

                        <span class="form-field__label">{{ $t('address.company') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" rules="required" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.contact">

                        <span class="form-field__label">{{ $t('address.contact') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
            <div v-else>
                <ValidationProvider v-slot="v" rules="required" tag="div">
                    <div class="form-field" :class="v.classes">
                        <input v-model="address.name">

                        <span class="form-field__label">{{ $t('address.names') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

            <ValidationProvider v-slot="v" rules="required" tag="div">
                <div class="form-field" :class="v.classes">
                    <AutosizeInput v-model="address.street" class="textarea--autosize"></AutosizeInput>

                    <span class="form-field__label">{{ $t('address.street') }}*</span>
                    <span class="form-field__error">{{ v.errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div v-if="isBillingAddress">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-8">
                            <!-- Zip -->
                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="address.zip">

                                    <span class="form-field__label">{{ $t('form.address.zip') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="cell small-16">
                            <!-- Location -->
                            <ValidationProvider v-slot="v" rules="required" tag="div">
                                <div class="form-field" :class="v.classes">
                                    <input v-model="address.location">

                                    <span class="form-field__label">{{ $t('form.address.locationString') }}*</span>
                                    <span class="form-field__error">{{ v.errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <ValidationProvider v-slot="v" rules="required" tag="div" mode="aggressive">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <LocationSelect v-model="address.locationObject" />

                        <span class="form-field__label">{{ $t('form.address.location') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>

            <ValidationProvider v-slot="v" rules="required" tag="div">
                <div class="form-field" :class="v.classes">
                    <input v-model="address.phone">

                    <span class="form-field__label">{{ $t('address.phone') }}*</span>
                    <span class="form-field__error">{{ v.errors[0] }}</span>
                </div>
            </ValidationProvider>
        </ValidationObserver>
    </div>
</template>

<script>
import LocationSelect from '@/components/LocationSelect.vue';
import AutosizeInput from '@/components/AutosizeInput.vue';

export default {
    name: 'RegisterAddress',
    components: { AutosizeInput, LocationSelect },
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        isBillingAddress: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        address: {
            type: 'company',
            contact: '',
            name: '',
            street: '',
            zip: '',
            location: '',
            locationObject: null,
            phone: '',
        },
    }),

    watch: {
        address: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },

        'address.locationObject': function locationObjectWatcher(value) {
            if (!value) {
                return;
            }

            this.address.location = value.name;
            this.address.zip = value.code;
        },
    },
};
</script>

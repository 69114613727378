<template>
    <div class="register-user-step">
        <h2 class="card__title">{{ $t('registerAddressesStep.title') }}</h2>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">

                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 large-12">
                            <ul class="checkbox-group checkbox-group--vertical">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <input id="register[addDefaultSenderAddress]" v-model="form.addDefaultSenderAddress" type="checkbox">
                                        <label for="register[addDefaultSenderAddress]" v-html="$t('register.form.addDefaultSenderAddress')"></label>
                                    </div>
                                </li>
                            </ul>

                            <div v-if="form.addDefaultSenderAddress">
                                <RegisterAddress v-model="form.defaultSenderAddress" name="sender" />
                            </div>
                        </div>
                        <div class="cell small-24 large-12">
                            <ul class="checkbox-group checkbox-group--vertical">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <input id="register[addDefaultReceiverAddress]" v-model="form.addDefaultReceiverAddress" type="checkbox">
                                        <label for="register[addDefaultReceiverAddress]" v-html="$t('register.form.addDefaultReceiverAddress')"></label>
                                    </div>
                                </li>
                            </ul>

                            <div v-if="form.addDefaultReceiverAddress">
                                <RegisterAddress v-model="form.defaultReceiverAddress" name="receiver" />
                            </div>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-12">
                            <button type="button" class="button button--white" @click.prevent="back">
                                {{ $t('global.back') }}
                            </button>
                        </div>
                        <div class="cell small-12 align-right">
                            <button type="submit" class="button button--arrow">
                                {{ $t('global.next') }}
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import RegisterAddress from '@/components/RegisterAddress.vue';

export default {
    name: 'RegisterAddressesStep',

    components: {
        RegisterAddress,
    },

    props: {
        initialData: {
            type: Object,
        },
    },

    data: () => ({
        form: {
            addDefaultSenderAddress: false,
            addDefaultReceiverAddress: false,

            defaultSenderAddress: null,
            defaultReceiverAddress: null,
        },

        loading: false,
    }),

    methods: {
        back() {
            this.$router.push({ name: 'RegisterUserStep' });
        },

        submit() {
            this.$emit('validated', {
                validated: true,
                step: 'addresses',
            });

            this.$router.push({ name: 'RegisterPaymentStep' });
        },
    },

    watch: {
        form: {
            handler(value) {
                this.$emit('stepData', value);
            },
            deep: true,
        },

        addDefaultSenderAddress(value) {
            if (!value) {
                this.form.defaultSenderAddress = null;
            }
        },

        addDefaultReceiverAddress(value) {
            if (!value) {
                this.form.defaultReceiverAddress = null;
            }
        },
    },

    mounted() {
        Object.keys(this.initialData).forEach((key) => {
            if (this.form.key === undefined) {
                return;
            }

            this.form[key] = this.initialData[key];
        });
    },
};
</script>

<template>
    <div class="register">
        <div class="grid-container">
            <div class="grid-x">
                <div class="cell small-24">
                    <h1 class="login__main-title" v-html="$t('register.mainTitle')"></h1>
                    <div class="card">
                        <!-- Stepper -->
                        <ul class="stepper">
                            <li class="stepper__item" :class="{ 'stepper__item--validated': validation.user, 'stepper__item--not-reachable': !isReachableUser, }">
                                <router-link :to="{ name: 'RegisterUserStep' }" class="stepper__link" tabindex="-1">
                                    <div class="stepper__state"/>
                                    <div class="stepper__label-container">
                                        <span class="stepper__label">{{ $t('register.navigation.user') }}</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="stepper__item" :class="{ 'stepper__item--validated': validation.addresses, 'stepper__item--not-reachable': !isReachableAddresses, }">
                                <router-link :to="{ name: 'RegisterAddressesStep' }" class="stepper__link" tabindex="-1">
                                    <div class="stepper__state"/>
                                    <div class="stepper__label-container">
                                        <span class="stepper__label">{{ $t('register.navigation.addresses') }}</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="stepper__item" :class="{ 'stepper__item--validated': validation.payment, 'stepper__item--not-reachable': !isReachablePayment, }">
                                <router-link :to="{ name: 'RegisterPaymentStep' }" class="stepper__link" tabindex="-1">
                                    <div class="stepper__state"/>
                                    <div class="stepper__label-container">
                                        <span class="stepper__label">{{ $t('register.navigation.payment') }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>

                        <keep-alive>
                            <router-view
                                @stepData="onStepData"
                                @validated="onValidated"

                                :initial-data="form"
                            />
                        </keep-alive>
                    </div>
                    <div class="login__footer" v-html="$t('register.footer')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    data: () => ({
        form: {},

        validation: {
            user: false,
            addresses: false,
            payment: false,
        },
    }),

    metaInfo() {
        return {
            title: this.$t('register.title'),
        };
    },

    computed: {
        isReachableUser() {
            return this.isStepAllowed('user');
        },

        isReachableAddresses() {
            return this.isStepAllowed('addresses');
        },

        isReachablePayment() {
            return this.isStepAllowed('payment');
        },
    },

    methods: {
        onStepData(data) {
            Object.assign(this.form, data);
        },

        onValidated(event) {
            this.validation[event.step] = event.validated;
        },

        isStepAllowed(name) {
            let allowed = true;
            const keys = Object.keys(this.validation);

            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];

                if (key === name) {
                    break;
                }

                allowed = allowed && this.validation[key];
            }

            return !!allowed;
        },
    },
};
</script>

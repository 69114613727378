<template>
    <div class="register-user-step">
        <h2 class="card__title">{{ $t('registerPaymentStep.title') }}</h2>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">

                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-24 large-12">
                            <fieldset class="form-part">
                                <legend class="form-part__title">
                                    {{ $t('register.form.invoiceAddressTitle') }}
                                </legend>

                                <RegisterAddress v-model="form.invoiceAddress" name="invoice" :is-billing-address="true" />
                            </fieldset>
                        </div>
                        <div class="cell small-24 large-12">
                            <fieldset class="form-part">
                                <legend class="form-part__title">
                                    {{ $t('register.form.invoiceDispatchTypeTitle') }}
                                </legend>

                                <ul class="radio-group">
                                    <li class="radio-group__item">
                                        <input id="invoiceDispatch-1" checked="checked" type="radio" value="email">
                                        <label for="invoiceDispatch-1">{{ $t('register.form.invoiceDispatchType.email') }}</label>
                                    </li>
                                </ul>

                                <ValidationProvider v-slot="v" rules="required|email" slim>
                                    <div class="form-field" :class="v.classes">
                                        <input v-model="form.invoiceMail">

                                        <span class="form-field__label">{{ $t('register.form.invoiceMail') }}*</span>
                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider v-slot="v" rules="required" slim>
                                    <div class="form-field" :class="v.classes">
                                        <select class="select" v-model="form.language" required>
                                            <option value="de">{{ $t('register.form.language.de') }}</option>
                                            <option value="fr">{{ $t('register.form.language.fr') }}</option>
                                            <option value="it">{{ $t('register.form.language.it') }}</option>
                                            <option value="en">{{ $t('register.form.language.en') }}</option>
                                        </select>

                                        <span class="form-field__label">{{ $t('register.form.languageLabel') }}*</span>
                                        <span class="form-field__error">{{ v.errors[0] }}</span>
                                    </div>
                                </ValidationProvider>

                                <small>{{ $t('register.form.invoiceDispatchDisclaimer') }}</small>
                            </fieldset>

                            <fieldset class="form-part">
                            </fieldset>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-12">
                            <button type="button" class="button button--white" @click.prevent="back">
                                {{ $t('global.back') }}
                            </button>
                        </div>
                        <div class="cell small-12 align-right">
                            <button type="submit" class="button button--arrow">
                                {{ $t('global.next') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import RegisterAddress from '@/components/RegisterAddress.vue';
import axios from '@/config/axios';
import { reverseTransform } from '@/services/register-transformer';

export default {
    name: 'RegisterPaymentStep',

    components: {
        RegisterAddress,
    },

    props: {
        initialData: {
            type: Object,
        },
    },

    data: () => ({
        form: {
            language: 'de',

            invoiceAddress: null,
            invoiceMail: '',
        },

        loading: false,
    }),

    methods: {
        back() {
            this.$router.push({ name: 'RegisterAddressesStep' });
        },

        submit() {
            this.$emit('validated', {
                validated: true,
                step: 'payment',
            });

            const data = reverseTransform(this.initialData, this.$i18n.locale);

            axios({
                url: '/api/v2/register',
                method: 'POST',
                data,
            }).then(() => {
                this.loading = false;

                this.$router.push({ name: 'RegisterThankYou' });
            });
        },
    },

    mounted() {
        this.form.language = this.$i18n.locale;

        Object.keys(this.initialData).forEach((key) => {
            if (this.form.key === undefined) {
                return;
            }

            this.form[key] = this.initialData[key];
        });
    },

    watch: {
        form: {
            handler(value) {
                this.$emit('stepData', value);
            },
            deep: true,
        },
    },
};
</script>

import { getSupportedLocale } from '@/config/i18n';
import { reverseTransform as reverseAddressTransform } from '@/services/address-transformer';

export function reverseTransform(form, locale) {
    const {
        username,
        password,
        passwordRepeat,
        email,

        type,
        firstname,
        lastname,
        street,
        zip,
        location,
        phone,

        newsletter,

        invoiceMail,
        invoiceDispatchType,
        invoiceType,
        language,
    } = form;

    const defaultSenderAddress = form.addDefaultSenderAddress ? reverseAddressTransform(form.defaultSenderAddress, false) : null;
    const defaultReceiverAddress = form.addDefaultReceiverAddress ? reverseAddressTransform(form.defaultReceiverAddress, false) : null;
    const defaultInvoiceAddress = reverseAddressTransform(form.invoiceAddress, true);

    return {
        // Login
        username,
        email,
        password: {
            first: password,
            second: passwordRepeat,
        },
        sprache: getSupportedLocale(locale),

        // Profile Address
        type,
        vorname: firstname,
        nachname: lastname,
        addresse: street,
        postleitzahl: zip,
        ort: location,
        phone,

        // Addresses
        default_absender: defaultSenderAddress,
        default_empfaenger: defaultReceiverAddress,
        default_rechnungsadresse: defaultInvoiceAddress,

        // Organisation
        invoice_dispatch_type: invoiceDispatchType,
        invoice_type: invoiceType,
        invoice_mail: invoiceMail,
        invoice_language: language,

        // Newsletter
        newsletter,
    };
}

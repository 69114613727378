<template>
    <div class="register-user-step">
        <h2 class="card__title">{{ $t('registerUserStep.title') }}</h2>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <!-- Salutation -->
                <ValidationProvider v-slot="v" rules="" tag="div">
                    <ul class="radio-group" :class="v.classes">
                        <li class="radio-group__item">
                            <input id="register[salutation][male]" type="radio" v-model="form.type" value="male">
                            <label for="register[salutation][male]">{{ $t('form.address.salutation.male') }}</label>
                        </li>
                        <li class="radio-group__item">
                            <input id="register[salutation][female]" type="radio" v-model="form.type" value="female">
                            <label for="register[salutation][female]">{{ $t('form.address.salutation.female') }}</label>
                        </li>
                    </ul>
                </ValidationProvider>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24 medium-24 large-12">
                        <!-- Firstname -->
                        <ValidationProvider v-slot="v" rules="required" tag="div">
                            <div class="form-field" :class="v.classes">
                                <input v-model="form.firstname">

                                <span class="form-field__label">{{ $t('form.address.firstname') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <!-- Lastname -->
                        <ValidationProvider v-slot="v" rules="required" tag="div">
                            <div class="form-field" :class="v.classes">
                                <input v-model="form.lastname">

                                <span class="form-field__label">{{ $t('form.address.lastname') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <!-- Street -->
                        <ValidationProvider v-slot="v" rules="required" tag="div">
                            <div class="form-field" :class="v.classes">
                                <AutosizeInput v-model="form.street" class="textarea--autosize"></AutosizeInput>

                                <span class="form-field__label">{{ $t('address.street') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="cell small-24 medium-10">
                                    <!-- Zip -->
                                    <ValidationProvider v-slot="v" rules="required" tag="div">
                                        <div class="form-field" :class="v.classes">
                                            <input v-model="form.zip">

                                            <span class="form-field__label">{{ $t('form.address.zip') }}*</span>
                                            <span class="form-field__error">{{ v.errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="cell small-24 medium-14">
                                    <!-- Location -->
                                    <ValidationProvider v-slot="v" rules="required" tag="div">
                                        <div class="form-field" :class="v.classes">
                                            <input v-model="form.location">

                                            <span class="form-field__label">{{ $t('form.address.locationString') }}*</span>
                                            <span class="form-field__error">{{ v.errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <!-- Phone -->
                        <ValidationProvider v-slot="v" rules="required" tag="div">
                            <div class="form-field" :class="v.classes">
                                <input v-model="form.phone">

                                <span class="form-field__label">{{ $t('form.address.phone') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <!-- E-Mail -->
                        <ValidationProvider v-slot="v" rules="required|email|uniqueEmail" tag="div">
                            <div class="form-field" :class="v.classes">
                                <input v-model.lazy="form.email">

                                <span class="form-field__label">{{ $t('form.address.email') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="cell small-24 medium-24 large-12">
                        <!-- Username -->
                        <ValidationProvider v-slot="v" rules="required|uniqueUsername" tag="div">
                            <div class="form-field" :class="v.classes">
                                <input v-model.lazy="form.username">

                                <span class="form-field__label">{{ $t('register.form.username') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <!-- Password -->
                        <ValidationProvider v-slot="v" rules="required" vid="password">
                            <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                <input v-model="form.password" type="password">

                                <span class="form-field__label">{{ $t('register.form.password') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <!-- Password repeat -->
                        <ValidationProvider v-slot="v" rules="required|confirmed:password">
                            <div class="form-field form-field--direct-evaluation" :class="v.classes">
                                <input v-model="form.passwordRepeat" type="password">

                                <span class="form-field__label">{{ $t('register.form.passwordRepeat') }}*</span>
                                <span class="form-field__error">{{ v.errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-24">
                        <div class="terms-of-service">
                            <ul class="checkbox-group checkbox-group--vertical">
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: true } }">
                                            <input id="register[newsletter]" v-model="form.newsletter" type="checkbox" :class="v.classes">
                                            <label for="register[newsletter]" v-html="$t('register.form.newsletter')"></label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                                <li class="checkbox-group__item">
                                    <div class="checkbox-group__input">
                                        <ValidationProvider v-slot="v" :rules="{ required: { allowFalse: false } }">
                                            <input id="register[tosAccepted]" v-model="form.tosAccepted" type="checkbox" :class="v.classes">
                                            <label for="register[tosAccepted]" v-html="$t('register.form.tosAccepted', { url: $t('links.tos') })"></label>
                                        </ValidationProvider>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-12">
                        <button type="button" class="button button--white" @click.prevent="back">
                            {{ $t('global.back') }}
                        </button>
                    </div>
                    <div class="cell small-12 align-right">
                        <button type="submit" class="button button--arrow">
                            {{ $t('global.next') }}
                        </button>
                    </div>
                </div>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import AutosizeInput from '@/components/AutosizeInput.vue';

export default {
    name: 'RegisterUserStep',
    components: { AutosizeInput },
    props: {
        initialData: {
            type: Object,
        },
    },

    data: () => ({
        form: {
            type: 'male',
            firstname: '',
            lastname: '',
            street: '',
            zip: '',
            location: '',
            phone: '',
            email: '',

            username: '',
            password: '',
            passwordRepeat: '',

            tosAccepted: false,
            newsletter: false,
        },

        loading: false,
    }),

    methods: {
        back() {
            this.$router.push({ name: 'Login' });
        },

        submit() {
            this.$emit('validated', {
                validated: true,
                step: 'user',
            });

            this.$router.push({ name: 'RegisterAddressesStep' });
        },

        togglePasswordType() {
            const attr = $(this.$refs.passwordField).attr('type');

            $(this.$refs.passwordField).attr('type', attr === 'password' ? 'text' : 'password');
        },
    },

    watch: {
        form: {
            handler(value) {
                this.$emit('stepData', value);
            },
            deep: true,
        },
    },

    mounted() {
        Object.keys(this.initialData).forEach((key) => {
            if (this.form.key === undefined) {
                return;
            }

            this.form[key] = this.initialData[key];
        });
    },
};
</script>
